<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <h3 class="font-normal">Logo library</h3>
      </div>
      <vs-row class="pullright mt-4 mb-8">
        <input type="file" name="companyLogo" v-validate="{ size: maxFileSize }" data-vv-as="logo" class="hidden" ref="uploadLogoInput" @change="uploadLogo" accept="image/png" />
        <vs-button @click="$refs.uploadLogoInput.click()" size="medium" v-round class="only-border-btn"><plus-icon size="1.5x" class="button-icon"></plus-icon>Upload Image</vs-button>
      </vs-row>
      <vs-row class="logo-lib-wrap" v-if="imageloaded" style="display:grid;grid-template-columns: repeat(auto-fit, minmax(223px, 1fr));gap: 24px;">
        <div class="logo-library" v-for="logo in logoLibrary" :key="logo.logoId">
          <div class="img-cont-border">
            <span class="img-icon">
              <feather-icon icon="Edit2Icon" class="cursor-pointer edit-icon" style="vertical-align: bottom" v-if="!logo.linked" @click="imageReplace(logo)" />
            </span>
            <div class="">
              <S3ImageGenerator :custom-class="'card-library-image'" :document="logo.logoImage" :is-base64="false" :key="logo.logoImage"/>
            </div>
          </div>
          <p class="img-caption mt-4">{{ logo.logoId }}</p>
        </div>
      </vs-row>
      <vs-row v-else>
        <em>No images uploaded.</em>
      </vs-row>
    </vs-card>

    <div class="centerx">
      <vs-popup class="holamundo" :title="popupTitle" :active.sync="popupReplaceActive">
        <vs-row>
          <form enctype="multipart/form-data">
            <div class="w-full">
              <div class="flex upload-img my-5">
                <div class="flex img-cont-border" v-if="replaceImage">
                  <div class="img-container w-64">
                    <S3ImageGenerator :custom-class="'card-library-image'" :document="replaceImage" :is-base64="logoChange" :key="replaceImage"/>
                  </div>
                  <span class="twelve-size-cont">
                    <feather-icon icon="XCircleIcon" class="twelve-size" @click="clearImage" />
                  </span>

                </div>
                <div class="flex img-cont-border" v-else>
                  <div class="img-container w-64">
                    <img
                      src="https://www.solidbackgrounds.com/images/2560x1600/2560x1600-dark-gray-solid-color-background.jpg"
                      alt="img" class="responsive" />
                  </div>
                </div>
                <input type="file" name="companyLogo" v-validate="{ size: maxFileSize }" data-vv-as="logo" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/png" />
                <div class="ml-20 mt-10">
                  <a @click="$refs.uploadImgInput.click()" class="upicon">
                    <template>
                      <upload-icon size="1.5x" class="custom-class"></upload-icon>
                    </template>
                    <u>Upload Image</u></a>
                </div>
              </div>
              <span class="text-danger text-sm" v-show="errors.has(`companyLogo`)">
                {{ errors.first(`companyLogo`) }}
              </span>
            </div>
          </form>
        </vs-row>
        <div class="button-section">
          <vs-button v-if="editMode" class="only-border-btn" size="medium" style="width: 200px; margin-right: 3rem" @click="deleteLogo()" v-round>Remove</vs-button>
          <vs-button :disabled="!logoChange" style="width: 200px; margin-right: 3rem" @click="uploadImage" v-round>
            Save</vs-button>
          <div class="cancel-btn" @click="cancelLogo"><u>Cancel</u></div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
  import S3ImageGenerator from "../../components/S3ImageGenerator";
  import { mapActions } from "vuex";
  import { PlusIcon, UploadIcon } from "vue-feather-icons";

  export default {
    components: {
      UploadIcon,
      PlusIcon,
      S3ImageGenerator
    },
    props: ["getGlobalSetting", "getLogos", "logoLibrary", "maxFileSize"],
    data() {
      return {
        merchantId: "",
        partnerId: "",
        popupReplaceActive: false,
        replaceImage: "",
        replaceImageId: "",
        companyLogo: "",
        logoChange: false,
        popupTitle: "",
        fonts: [],
        loaded: false,
        imageloaded: true,
        editMode: false,
      };
    },
    computed: {
      validateForm() {
        return !this.errors.any();
      },
    },

    methods: {
      ...mapActions("logo", ["fetchLogosByMerchantId", "uploadLogosByMerchantId", "updateLogoById", "removeLogo"]),

      onCancel() {
        this.$router.push({
          name: "settings"
        });
      },

      cancelLogo() {
        this.replaceImage = null;
        this.replaceImageId = null;
        this.popupReplaceActive = false;
        this.logoChange = false;
        this.editMode = false;
        if (this.errors.has("companyLogo")) {
          this.errors.remove("companyLogo");
        }
      },

      clearImage() {
        this.replaceImage = null;
        this.$refs.uploadImgInput.value = "";
        this.$refs.uploadLogoInput.value = "";
        if (this.errors.has("companyLogo")) {
          this.errors.remove("companyLogo");
        }
      },

      getPartnerId() {
        let user = JSON.parse(localStorage.getItem("user"));
        this.partnerId = user.userType === "admin" ? user._id : user.partnerId;
      },

      imageReplace(e) {
        this.popupTitle = "Replace Logo";
        this.replaceImage = e.logoImage;
        this.replaceImageId = e.logoId;
        this.popupReplaceActive = true;
        this.editMode = true;
      },

      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          this.companyLogo = input.target.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            this.replaceImage = e.target.result;
            this.logoChange = true;
          };
          reader.readAsDataURL(input.target.files[0]);
        }
      },

      uploadLogo(input) {
        this.cancelLogo();
        this.popupTitle = "Upload Logo";
        if (input.target.files && input.target.files[0]) {
          this.popupReplaceActive = true;
          this.companyLogo = input.target.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            this.replaceImage = e.target.result;
            this.logoChange = true;
          };
          reader.readAsDataURL(input.target.files[0]);
        }
      },
      async uploadImage() {
        if (this.errors.has("companyLogo")) {
          return false;
        }
        this.imageloaded = false;
        let formData = new FormData();
        formData.append("merchantId", this.merchantId);
        formData.append("logoImage", this.companyLogo);

        if (!this.companyLogo) {
          formData.set("image", this.replaceImage);
        }

        if (this.replaceImageId) {
          formData.append("logoId", this.replaceImageId);
          let obj = {
            logoId: this.replaceImageId,
            merchantId: this.merchantId,
            data: formData,
            config: {
              header: {
                "Content-Type": "multipart/form-data",
              },
            },
          };
          this.$vs.loading();
          await this.updateLogoById(obj).then((result) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Company Logo",
              text: "Update Success",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.cancelLogo();
            this.getLogos();
            this.imageloaded = true;
          }).catch((ex) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Company Logo",
              text: ex.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
        } else {
          let obj = {
            id: this.merchantId,
            data: formData,
            config: {
              header: {
                "Content-Type": "multipart/form-data",
              },
            },
          };
          this.$vs.loading();
          await this.uploadLogosByMerchantId(obj).then((result) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Company Logo",
              text: "Upload Success",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.cancelLogo();
            this.getLogos();
            this.imageloaded = true;
          }).catch((ex) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Company Logo",
              text: ex.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
        }

        this.getGlobalSetting();
      },

      deleteLogo() {
        const payload = {
          logoId: this.replaceImageId,
          merchantId: this.merchantId
        };

        this.$vs.loading();
        this.removeLogo(payload).then((response) => {
          this.logoLibrary = response.data.data;
          this.popupReplaceActive = false;
          this.editMode = false;
          this.$vs.loading.close();
          this.getGlobalSetting();
          this.getLogos();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: ex.data.title,
            text: ex.data.message,
            iconPack: "feather",
            color: "danger",
          });
        });
      },
    },

    beforeMount() {
      this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
      this.getPartnerId();
    },
  };

</script>